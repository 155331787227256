import React from 'react';
import '../assets/css/Footer.css';

function Footer() {
  return (
    <div className='footer-container'>
      EGEMEN SAHIN © 2025 
      <span className='footer-links'>
        <a className='footer-link' href="https://www.instagram.com/egemenshnq/">
          <i className="fa-brands fa-instagram"></i>
        </a>
        <a className='footer-link' href="https://www.linkedin.com/in/egemen-sahin-966359263/">
          <i className="fa-brands fa-linkedin"></i>
        </a>
        <a className='footer-link' href="https://github.com/EgemenShn01">
          <i className="fa-brands fa-github"></i>
        </a>
        <a className='footer-link' href="https://medium.com/@egemensahin">
        <i class="fa-brands fa-medium"></i>
                </a>
      </span>
    </div>
  );
}

export default Footer;
