import React from 'react';
import '../assets/css/About.css';
import profileImage from '../assets/images/cocukgelisimi.jpg';

function About() {
  return (
    <div className='container'>
      <div className='about-container'>
        <div className='textSection'>
          <h2>Hakkımda</h2>
          <p>
            Ben İnanç Hallumoğlu. Kırklareli Üniversitesi Çocuk Gelişimi bölümü lisans mezunuyum ve şu anda çocuk gelişimi alanında bir yıllık deneyimimle çalışıyorum. Bu süre zarfında, çocukların fiziksel, bilişsel, sosyal ve duygusal gelişimlerini destekleyen projelerde aktif olarak yer aldım.
          </p>
          <p>
            Amacım, her çocuğun bireysel potansiyelini keşfetmesine yardımcı olmak ve onların sağlıklı bir gelişim süreci geçirmelerini sağlamaktır. Çocukların ihtiyaçlarını ön planda tutarak, ailelere ve eğitimcilere rehberlik etmeye devam ediyorum. Bu alandaki çalışmalarımda, "duyusal bütünleme", "gelişimsel değerlendirme", "oyun terapisi" ve "pozitif disiplin" gibi yöntemleri kullanarak, her çocuğun farklı ihtiyaçlarına uygun bireysel stratejiler geliştirmekteyim.
          </p>
        </div>
        <div className='imageSection'>
          <img src={profileImage} alt="İnanç Hallumoğlu" className='profileImage' />
        </div>
      </div>
    </div>
  );
}

export default About;
