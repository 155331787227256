import React, { useState } from 'react';
import Slider from "react-slick";
import '../assets/css/App.css';
import bannerImg from '../assets/images/hatunresim1.jpg';
import belge1 from '../assets/images/ünibelge.pdf.jpg';
import belge2 from '../assets/images/kursbelge.jpg';

const Hero = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    customPaging: (i) => (
      <div
        style={{
          width: "10px",
          height: "10px",
          background: activeIndex === i ? "black" : "white",
          borderRadius: "50%",
          margin: "0 5px",
        }}
      />
    ),
    afterChange: (current) => setActiveIndex(current),
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  
  
  return (
    <div className="hero-container">
      <Slider {...settings} className="slider">
        <div className="slide md">
          <h2>Üniversite Belge</h2>
          <img src={belge1} alt="Banner" width={300} />
          </div>
        <div className="slide md">
          <h2>Kurs Sertifika</h2>
          <img src={belge2} alt=" Banner" width={300} />
          </div>
      </Slider>
      <div className='Imagesss'>
        <img src={bannerImg} alt="Banner" width={300} />
      </div>
    </div>
  );
}

export default Hero;
