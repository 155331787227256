import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import Navbar from './components/Navbar';
import Hero from './components/Hero';
import Services from './components/Services';
import About from './components/About';
import Contact from "./components/Contact";
import Footer from "./components/Footer";

import './assets/css/App.css';

function App() {
  return (
    <div>
      <div className='App'>
        <Navbar />
        <div>
        <section id="hero" className="app1">
          <Hero/>
        </section>
        </div>
      </div>
      <section id="services" className="secSer">
        <Services/>
      </section>
      <section id="about" >
        <About/>
      </section>
      <section id="contact" className="mt-5">
        <Contact/>
      </section>   
      <section id="footer">
        <Footer/>
      </section>
    </div>
  );
}

export default App;
