import React, { useState } from 'react';
import '../assets/css/App.css';
import bannerImg from '../assets/images/hatunlogo2.jpg';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className={`navbar ${isOpen ? 'open' : ''}`}>
        <div className="navbar-brand">
          <img src={bannerImg} alt="Banner"  className='logo' width={50} />
          <div className='textName'>
          <h3>İnanç Hallumoğlu</h3>
          <h4>cg.inanchallumoglu@gmail.com</h4>
          </div>
        </div>
      <div className="hamburger" onClick={toggleMenu}>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>
      <div className={`navbar-links ${isOpen ? 'show' : ''}`}>
        <a href="#hero">Ana Sayfa</a>
        <a href="#about">Hakkımda</a>
        <a href="#services">Hizmetlerimiz</a>
        <a href="#contact">İletişim</a>
      </div>
    </nav>
  );
};

export default Navbar;
