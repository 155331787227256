import React from 'react';
import { Tilt } from 'react-tilt';
import '../assets/css/Services.css';

import duyu from '../assets/images/duyu.png';
import gelisim from '../assets/images/gelişim.png';
import oyun from '../assets/images/oyun.png';
import pozitif from '../assets/images/pozitif.png';

const Services = () => {
  const services = [
    { icon: duyu, title: 'Duygusal Bütünlenme' },
    { icon: gelisim, title: 'Gelişimsel Değerlendirme' },
    { icon: oyun, title: 'Oyun Terapisi' },
    { icon: pozitif, title: 'Pozitif Disiplin' }
  ];

  return (
    <div className="services">
      <div className='textSev'>
        <h2>Kazanımlarım</h2>
      </div>
      <div className='services-container'>
        {services.map((service, index) => (
          <Tilt className="tilt-card" key={index} options={{ max: 70, scale: 1.05 }}>
            <div className="service-card">
              <div className="service-icon">
                <img src={service.icon} alt={service.title} className="service-image" />
              </div>
              <h4 className="service-title">{service.title}</h4>
            </div>
          </Tilt>
        ))}
      </div>
    </div>
  );
};

export default Services;
