import React, { useRef } from "react";
import '../assets/css/Contact.css';

const Contact = () => {
  const form = useRef();

  const handleSubmit = (e) => {
    e.preventDefault();

    fetch('https://formspree.io/f/xzzbzlzl', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
      },
      body: new FormData(form.current),
    })
      .then((response) => {
        if (response.ok) {
          window.toastr.success('E-posta başarıyla gönderildi!', 'Başarılı');
          form.current.reset(); // Formu temizlemek için
        } else {
          window.toastr.error('E-posta gönderilemedi.', 'Hata');
        }
      })
      .catch((error) => {
        window.toastr.error('Hata oluştu: ' + error.message, 'Hata');
      });
  };

  return (
    <div className="contact-container">
      <form ref={form} onSubmit={handleSubmit} className="contact-form">
        <h3 className="contact-header">İletişim</h3>

        <label className="contact-label">
          <span>İsim</span>
          <input
            type="text"
            name="user_name"
            className="contact-input"
            placeholder="İsminizi giriniz?"
            required
          />
        </label>

        <label className="contact-label">
          <span>E-Posta</span>
          <input
            type="email"
            name="user_email"
            className="contact-input"
            placeholder="E-posta adresinizi giriniz"
            required
          />
        </label>

        <label className="contact-label">
          <span>Mesajınız</span>
          <textarea
            rows={5}
            name="message"
            className="contact-textarea"
            placeholder="Mesajınızı yazınız"
            required
          />
        </label>

        <div className="contact-buttons">
          <button type="submit" className="contact-submit-btn">
            Gönder
          </button>

          <div className="contact-links">
            <a href="/#"><i className="fa-brands fa-linkedin"></i></a>
            <a href="https://www.instagram.com/cg.inanchallumoglu"><i className="fa-brands fa-instagram"></i></a>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Contact;
